<template lang="pug">
div(v-if="$store.state.app.accessSySafra")
  v-container(fluid)
    DataFilter(
      :filterProps="filters"
      :paginate="true"
      :items="dataset"
    )
      template(v-slot:actions)

        v-tooltip(bottom)
          template(v-slot:activator="{ on }")
            v-btn(color="primary" v-on="on"  @click="getData" fab small class="ml-2 mr-2")
              v-icon(small) fa-solid fa-rotate-right
          span Atualizar

        v-tooltip(bottom)
          template(v-slot:activator="{ on }")
            v-btn(color="primary" v-on="on" to="/sysafra/sysbackup/monitor" fab small)
              v-icon(small) mdi-page-previous-outline
          span Voltar ao monitor

      template(v-slot:default="{ items }")
        v-data-table(
          dense
          disable-pagination
          hide-default-footer
          single-expand
          :headers="headers"
          :items="items"
        )

          template(v-slot:item.cliente="{ item }")
            span {{ item.cliente }}

          template(v-slot:item.backup.datahorainicio="{ item }")
            v-tooltip(bottom)
              template(v-slot:activator="{ on }")
                div(v-on="on") {{ new Date(item.backup.datahorainicio).toLocaleString() }}
              span {{ item.idbackup}}

          template(v-slot:item.datahorainicio="{ item }")
            span {{ new Date(item.datahorainicio).toLocaleString() }}

          template(v-slot:item.status="{ item }")
            v-tooltip(bottom)
              template(v-slot:activator="{ on }")
                v-icon(v-on="on" v-if="item.status == 'FINALIZADO'" color="success") mdi-check
                v-icon(v-on="on" v-else-if="item.status == 'ERRO'" color="error") mdi-close
                v-icon(v-on="on" v-else color="primary") mdi-clock-outline
              span {{ textStatus(item) }}

          template(v-slot:item.ativo="{ item }")
            v-tooltip(bottom)
              template(v-slot:activator="{ on }")
                v-icon(v-on="on" v-if="item.expirado == false" color="success") mdi-circle
                v-icon(v-on="on" v-else-if="item.expirado == null" color="while")
                v-icon(v-on="on" v-else color="grey") mdi-circle
              span {{ item.expirado != null ? 'Disponível' : 'Expirado' }}

          template(v-slot:item.actions="{ item }")
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-btn(
                    v-on="on"
                    v-bind="attrs"
                    width="30"
                    height="30"
                    color="primary"
                    icon
                    @click="showDialogHistory(item)"
                    outlined
                    small
                  )
                  v-icon mdi-history
              span Histórico

  v-dialog(v-model="dialogHistorico" v-if="dialogHistorico" max-width="800px" height="600px")
    v-card

      v-card-title
        span Histórico do download
        v-spacer
        v-btn(@click="dialogHistorico = false" icon)
          v-icon mdi-close

      v-card-text
        v-row(no-gutters)
          v-col(cols="12" sm="2" md="2")
            b Cliente
          v-col(cols="12" sm="10" md="10")
            span {{ dialogData.idcliente }} - {{ dialogData.cliente }}
          v-col(cols="12" sm="2" md="2")
            b Hash Backup
          v-col(cols="12" sm="10" md="10")
            span {{ dialogData.backup.id }}
          v-col(cols="12" sm="2" md="2")
            b Solicitado por
          v-col(cols="12" sm="10" md="10")
            span {{ dialogData.usuario }}
          v-col(cols="12" sm="2" md="2")
            b Enviado para
          v-col(cols="12" sm="10" md="10")
            span {{ dialogData.emailsdestino.join(', ') }}

        v-tabs(v-model="tabs")
          v-tab(v-for="row in dialogData.items" :key="row.id") {{ row.tipo }}
        v-tabs-items(v-model="tabs")
          v-tab-item(v-for="row in dialogData.items" :key="row.id" style="width: -webkit-fill-available;")

            v-row(no-gutters class="mt-4 ml-4")
              v-col(cols="12" sm="2" md="2")
                b Inicio:
              v-col(cols="12" sm="10" md="10")
                span {{ row.datahorainicio !== null ? new Date(row.datahorainicio).toLocaleString() : '' }}
              v-col(cols="12" sm="2" md="2")
                b Fim:
              v-col(cols="12" sm="10" md="10")
                span {{ row.datahorafim !== null ? new Date(row.datahorafim).toLocaleString() : ''}}
              v-col(cols="12" sm="2" md="2")
                b Expiração:
              v-col(cols="12" sm="10" md="10")
                span {{ row.expiracao !== null ? new Date(row.expiracao).toLocaleString() : ''}}
              v-col(cols="12" sm="2" md="2" class="mt-2")
                b Status:
              v-col(cols="12" sm="10" md="10" class="mt-2")
                v-chip(small :color="row.status == 'ERRO' ? 'error' : row.status == 'FINALIZADO' ? 'success' : 'info'  ") {{ row.status }}
              v-col(cols="12" sm="2" md="2" class="mt-5")
                b Arquivos:
              v-col(cols="12" sm="10" md="10" class="mt-5")
                span {{ row.arquivos.join(', ') }}

            v-data-table(
              class="mt-4"
              dense
              disable-pagination
              hide-default-footer
              show-expand
              :headers="headersDialog"
              :items="row.logs"
            )

              template(v-slot:item.datahora="{ item }")
                span {{ new Date(item.datahora).toLocaleString() }}

              template(v-slot:item.data-table-expand="{ item, isExpanded, expand }")
                v-icon(v-if="item.detalhe !== null"
                  @click="expand(!isExpanded)"
                ) mdi-chevron-down

              template(v-slot:expanded-item="{ headers, item }")
                td(:colspan="headers.length") {{ item.detalhe }}

  </template>

<script>
import { mapMutations } from 'vuex';
import csapi from '@/api/csapi';

export default {
  data: () => ({
    filters: { includeSearch: true, items: [] },
    headers: [
      { text: 'ID', value: 'id', width: '60', sortable: false  },
      { text: 'Cliente', value: 'cliente', sortable: false },
      { text: 'Backup', value: 'backup.datahorainicio', sortable: false },
      { text: 'Solicitado', value: 'datahorainicio', sortable: false },
      { text: 'Usuário', value: 'usuario', width: '200', sortable: false },
      { text: 'E-mail para envio', value: 'emailsdestino', width: '200', sortable: false },
      { text: 'Motivo', value: 'motivo', sortable: false },
      { text: 'Status', value: 'status', align: 'center', width: '80', sortable: false },
      { text: 'Download Disponível', value: 'ativo', align: 'center', width: '80', sortable: false },
      { text: '#', value: 'actions', align: 'center', width: '80', sortable: false },
    ],
    dataset: [],
    headersDialog: [
      { text: 'Data', value: 'datahora', width: '190', sortable: false },
      { text: 'Mensagem', value: 'mensagem.mensagem', sortable: false },
      { text: '#', value: 'data-table-expand', align: 'center', width: '80', sortable: false },
    ],
    dialogLine1: '',
    dialogLine2: '',
    dialogHistorico: false,
    dialogData: {},
    tabs: 0,
  }),
  methods: {
    ...mapMutations('app', ['setTitle', 'isControl']),
    ...mapMutations('loader', ['showLoader', 'hideLoader']),

    textStatus(item) {
      return item.status == 'ERRO' 
        ? item.items[0].logs[item.items[0].logs.length - 1].mensagem.mensagem 
        : item.status == 'FINALIZADO' 
          ? 'Concluído' 
          : 'Solicitado/Em Execução'
    },

    async getData() {
      this.showLoader();

      const res = await csapi.get('/sysafra/v1/sysbackup/recuperacao');
      if (res.status !== 200) {
        this.hideLoader();
        this.$toast.error('Erro ao carregar dados');
        return;
      }

      this.dataset = res.data;

      this.dataset.forEach((item) => {
        item.idcliente = item.backup.cliente.id;
        item.cliente = item.backup.cliente.nome;
        item.usuario = item.usuario.indexOf(':') > -1 ? item.usuario.split(':')[1] : item.usuario;
        item.expirado = item.items[0].expiracao == null ? null : new Date(item.items[0].expiracao) < new Date();
        item.status = item.items.map((item) => item.status).includes('ERRO') ? 'ERRO'
          : item.items.map((item) => item.status).includes('EXECUTANDO') ? 'EXECUTANDO'
            : item.items.map((item) => item.status).includes('PENDENTE') ? 'PENDENTE' : 'FINALIZADO';
        item.items.forEach((item) => {
          item.ultimamensagem = item.logs.length > 0 ? item.logs[item.logs.length - 1].mensagem.mensagem : '';
        });
      });

      this.hideLoader();
      if (this.dataset.length > 0) this.setFilters();
    },

    showDialogHistory(item) {
      this.tabs = 0;
      this.dialogLine1 = `${item.cliente} - ${item.backup.id}`;
      this.dialogLine2 = `Solicitado por: ${item.usuario} / Enviado para: ${item.emailsdestino}`;
      this.dialogHistorico = true;
      this.dialogData = item;
    },

    setFilters() {
      this.filters.items.length = 0;

      // Filtro de Clientes
      try {
        this.filters.items.push(
          {
            text: 'Cliente',
            type: 'select',
            values: this.dataset.map((item) => ({ field: 'cliente', text: item.cliente, value: item.cliente })),
          },
        );
      } catch (error) {
        console.error(error);
      }

      // Filtro de Usuários
      try {
        this.filters.items.push(
          {
            text: 'Usuário',
            type: 'select',
            values: this.dataset.map((item) => ({ field: 'usuario', text: item.usuario, value: item.usuario })),
          },
        );
      } catch (error) {
        console.error(error);
      }

      // Filtro de Status
      try {
        this.filters.items.push(
          {
            text: 'Status',
            type: 'select',
            values: [
              { field: 'status', text: 'Pendente', value: 'PENDENTE' },
              { field: 'status', text: 'Concluído', value: 'CONCLUIDO' },
            ],
          },
        );
      } catch (error) {
        console.error(error);
      }

      // Filtro de Download Disponível
      try {
        this.filters.items.push(
          {
            text: 'Download Disponível',
            type: 'select',
            values: [
              { field: 'expirado', text: 'Disponível', value: false },
              { field: 'expirado', text: 'Expirado', value: true },
              { field: 'expirado', text: 'Não Gerado', value: null },
            ],
          },
        );
      } catch (error) {
        console.error(error);
      }
    },
  },

  created() {
    if (!this.$store.state.app.accessSySafra) {
      this.$store.state.app.redirectToControl();
    }
  },

  mounted() {
    if (!this.$store.state.app.accessSySafra) return;

    this.setTitle('SySBackup - Solicitações de Backups');
    this.isControl(false);

    this.getData();
  },
};

</script>
